/**
 * @author Matthew Castle
 *
 * Contains a forcelink footer
 */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

const ForcelinkFooter = () => (
  <div
    css={css`
      width: fit-content;
      display: flex;
      flex-direction: row;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      text-align: left;
      color: #ffffff;

      button {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        color: #ffffff;
        border: none;
        background-color: transparent;
        padding: 0px;
        padding-left: 5px;

        :hover {
          text-decoration: underline;
        }

        :focus {
          outline: none;
        }
      }
    `}
  >
    <div
      css={css`
        padding-right: 5px;
        border-right: 1px solid #fff;
        cursor: pointer;

        :hover {
          text-decoration: underline;
        }
     `}
      role="button"
      tabIndex={0}
      onClick={() => {
        window.open('https://www.forcelink.net');
      }}
    >
      Powered by Forcelink&trade;
      {' '}
      {new Date().getFullYear()}
    </div>
    <button
      type="button"
      onClick={() => {
        window.open('https://www.forcelink.net/privacy-policy');
      }}
    >
      Privacy Policy
    </button>
  </div>
);

export default ForcelinkFooter;
