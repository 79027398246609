/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import { Link, Switch } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import '../../styles/headerandfooter.scss';

const headerProps = (item, showDropdown, setShowDropdown) => ({
  show: showDropdown[item],
  onMouseEnter: () => setShowDropdown((state) => ({ ...state, [item]: true })),
  onMouseLeave: () => setShowDropdown((state) => ({ ...state, [item]: false })),
  className: `basic-nav-dropdown outerLinkContainer ${
    showDropdown[item] ? 'showDropdown' : ''
  }`,
});

const Header = ({
  history,
  logoSrc,
  dropdowns,
}) => (
  <Navbar className="logoHeaderWithNav" collapseOnSelect expand="md">
    <Navbar.Brand id="headerLogoCol">
      <div
        tabIndex={0}
        role="button"
        onClick={() => history.push('/home')}
        css={css`
          width: 150px;
          padding: 4px 8px;
          border: 1px solid #F0F0F0;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          `}
      >
        <img
          alt="Portal home"
          src={logoSrc}
          className="d-inline-block align-top"
        />
      </div>
    </Navbar.Brand>
    <Switch>
      <HeaderNav {...{ dropdowns }} />
    </Switch>
  </Navbar>
);

const HeaderNav = ({ dropdowns }) => {
  const items = dropdowns.map(([item]) => item);
  const itemsState = items.reduce((state, item) => {
    const stated = state;
    (stated[item] = false);
    return stated;
  }, {});

  const [showDropdown, setShowDropdown] = useState({
    ...itemsState,
  });
  const props = (item) => headerProps(item, showDropdown, setShowDropdown);

  return (
    <>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" id="headerLinks">
          {dropdowns.map(([stateName, Icon, title, itms, onClick], i) => (
            <HeaderDropdown
              key={i}
              {...props(stateName)}
              {...{ Icon, title, itms }}
              {...(onClick && { onClick })}
            />
          ))}
        </Nav>
        <Nav className="justify-content-end">
          <Navbar.Text css={css`
            color: #262626;
            padding: 10px;
            border: 1px solid #F0F0F0;
            border-radius: 4px;
            `}
          >
            <Link
              to="/logout"
              className="link-item"
              css={css`
                display: flex;
                margin: 0px !important;
                padding: 0px !important;
                height: fit-content !important;
                width: fit-content !important;
              `}
            >
              <LogoutIcon css={css`
                  color: #262626; 
                  width: 24px !important;
                  height: 24px !important;
                `}
              />
            </Link>
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </>
  );
};

const HeaderDropdown = ({
  itms, Icon, title, ...props
}) => (
  <NavDropdown
    {...props}
    className={itms.length === 0 ? 'basic-nav-dropdown outerLinkContainer nodropdown' : 'basic-nav-dropdown outerLinkContainer'}
    title={(
      <Nav.Link
        disabled
        to=""
        className="innerLinkContainer"
        css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 4px;

            .headerLinksText {
              font-size: 14px;
              font-weight: 500;
            }
          `}
      >
        <Icon css={css`
          width: 24px !important;
          height: 24px !important;
        `}
        />
        <p className="headerLinksText">{title}</p>
      </Nav.Link>
    )}
  >
    <HeaderDropdownItems items={itms} />
  </NavDropdown>
);

const HeaderDropdownItems = ({ items }) => items.map(([onClick, name], i, arr) => {
  const isPath = typeof onClick === 'string';
  const props = {
    onClick: (e) => e.stopPropagation(),
    ...(isPath ? { to: onClick } : { onClick }),
  };
  return (
    <React.Fragment key={i}>
      {isPath
        ? (
          <Link {...props}>
            <div className="dropdown-item">{name}</div>
          </Link>
        ) : (
          <div {...props}>
            <div className="dropdown-item">{name}</div>
          </div>
        )}
      {i !== arr.length - 1 && <NavDropdown.Divider />}
    </React.Fragment>
  );
});

export default Header;
