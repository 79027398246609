import { apiUrl } from '../../constants/constantsGlobal';
import { fetchApi } from '../../functions/apiCalls';

export const shouldUserUpdateDetails = () => fetchApi(
  `${apiUrl}customermanager/isUserMustUpdateContactDetails`,
  'GET',
).then((res) => res.json());

export const getKPIData = (hql) => {
  const data = {
    active: true,
    aspect: '',
    dashboards: [],
    dataCall: hql,
    dataCallType: 'SQL',
    displayZero: false,
    footer: '',
    name: '',
    properties: '',
    subtitle: '',
    type: 'KPI',
    uuid: '',
  };
  return fetchApi(`${apiUrl}dashboardelementmanager/getDashboardElementData`, 'POST', {
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) {
        return res.text();
      }
      return '';
    })
    .then((value) => value)
    .catch((e) => {
      console.error(e);
      return '';
    });
};
