/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from './functions/ssg_facilities/context';
import Login from './pages/ssg_facilities/login';
import Home from './pages/ssg_facilities/home';
import ViewTickets from './pages/ssg_facilities/viewTickets';
import WorkOrderRequestPage from './pages/ssg_facilities/workOrderRequest';
import CustomerDetailsPage from './pages/ssg_facilities/customerDetails';
import Background from './components/ssg_facilities/background';
import ForcelinkFooter from './components/ssg_facilities/footer';
import SSGFacilitiesHeader from './components/ssg_facilities/navbar';

export class SSGFacilitiesApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  getFaviconEl = () => (document.getElementById('favicon'));

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'SSG Facilities Portal';
    const favicon = this.getFaviconEl();
    favicon.href = 'ssg_facilities/logo_small.png';
    const { openSnackbar } = this.state;
    const {
      authenticated,
      logout,
      cookies: { cookies },
    } = this.context;
    console.log('Authenticated:', authenticated);

    if (authenticated === null) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    const headerPaths = [
      '/home',
      '/details',
      '/tickets',
      '/ticket',
    ];

    const pagesPaths = [
      ['/details', <CustomerDetailsPage />],
      ['/tickets', <ViewTickets />],
      ['/ticket/:code', <WorkOrderRequestPage />],
      ['/ticket', <WorkOrderRequestPage />],
    ];

    return (
      <SSGFacilitiesWrapper>
        <Switch>
          {headerPaths.map((path, i) => (
            <Route path={path} key={i}>
              <SSGFacilitiesHeader paths={headerPaths} />
            </Route>
          ))}
        </Switch>
        <div id="App">
          <div css={css`padding-bottom: 24px;`} />
          <Switch>
            {[
              ['/login', <Login />],
            ].map(([path, page], i) => (
              <Route
                key={i}
                path={path}
                render={({ location }) => (!authenticated ? (
                  page
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
            ))}
            {!authenticated && (
            <Route
              path="/"
              render={({ location }) => (
                <Redirect
                  to={{ pathname: '/login', state: { from: location } }}
                />
              )}
            />
            )}
            <Route
              exact
              path="/"
              render={({ location }) => (
                <Redirect
                  to={{ pathname: '/home', state: { from: location } }}
                />
              )}
            />
            <Route
              path="/home"
              render={({ location }) => (authenticated ? (
                <Home />
              ) : (
                <Redirect
                  to={{ pathname: '/login', state: { from: location } }}
                />
              ))}
            />
            <Route path="/logout" render={() => ((logout(), null))} />

            {pagesPaths.map(([path, page], i) => (
              <Route path={path} key={i}>
                {page}
              </Route>
            ))}
            <Route
              path="/exports"
              component={() => {
                console.log('Navigating to exports table');
                window.location.replace(
                  'https://servest.forcelink.net/forcelink/exports.html',
                );
                return <h4>Redirecting...</h4>;
              }}
            />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
          <ForcelinkFooter />
        </div>
        {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
            >
              <Alert
                onClose={this.snackbarClose}
                severity="info"
                elevation={6}
                variant="filled"
              >
                Logged in with username
                {' '}
                <b>{cookies.username}</b>
              </Alert>
            </Snackbar>
        )}
      </SSGFacilitiesWrapper>
    );
  }
}

const SSGFacilitiesWrapper = (props) => {
  const {
    children,
  } = props;
  const loc = useLocation();
  let className;
  if (loc.pathname === '/login') className = 'withBackgroundImg';
  else className = 'withBackgroundColor';

  return (
    <div
      id="SSGFacilitiesApp"
      className={className}
      css={css`
          height: fit-content;
          min-height: 100vh;
          width: 100vw;
          display: flex;
          flex-direction: column;
          -moz-box-align: center;
          align-items: center;
          -moz-box-pack: center;
          justify-content: center;
          position: relative;
        `}
    >
      <div
        css={css`
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
        `}
      >
        <Background />
      </div>
      {children}
    </div>
  );
};

const NoMatch = () => {
  const location = useLocation();
  document.title = '404 Not Found | SSG Facilities';
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
