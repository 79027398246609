import React from 'react';
import { Context } from '../../functions/ssg_facilities/context';
import * as login from '../../scripts/loginScripts';
import { LoginForm } from '../../components/ssg_facilities/loginForm';

class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'ssg_fac';
    document.title = 'SSG Facilities Portal';
  }

  onLogin = (username, password, setStates) => {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    login.doLogin(username, password, setAuth, subscriberId, setStates);
  };

  render() {
    const { onLogin, subscriberId } = this;
    const subscriber = 'SSG Facilities';
    return (
      <LoginForm {...{ subscriber, onLogin, subscriberId }} />
    );
  }
}

export default Login;
