/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  ListGroup,
} from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs';
import { Context } from '../../functions/ssg_facilities/context';
import {
  getWorkRequest,
  postWorkRequest,
  byClassAndProp,
  getPortalCustomerDetails,
  addWorkRequestAttachment,
  getUserRoles,
  getWorkRequestNoteTypes,
  addNoteToWorkRequest,
  getCustomLookup1ValuesSsg,
} from '../../functions/apiCalls';
import {
  getNotes,
  getRootItemByClassAndProp,
} from '../../scripts/botswanatourism/scripts';
import ImageViewerUploader from '../../components/ImageViewerUploader';

const WorkOrderRequestPage = () => {
  const params = useParams();
  const { code } = params;
  const { labels } = useContext(Context);
  const history = useHistory();
  const [workRequest, setWorkRequest] = useState();
  const [customLookup1, setCustomLookup1] = useState([]);
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [adding, setAdding] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const [isAddNote, setIsAddNote] = useState(false);
  const [noteText, setNoteText] = useState('');

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: workRequest });

  useEffect(() => {
    document.title = code
      ? `Edit Ticket ${code} | SSG Facilities Portal`
      : 'Create Ticket | SSG Facilities Portal';

    async function fetchData() {
      setLoading(true);

      const lookup1Res = await getRootItemByClassAndProp(
        'WorkOrderRequest',
        'customLookup1',
        '',
      );

      const detailsResponse = await getPortalCustomerDetails().then((res) => res.json());
      const response2 = await getUserRoles();
      detailsResponse.role = [];
      const role = [];
      for (let i = 0; i < response2.length; i++) {
        role.push(response2[i].label);
      }
      detailsResponse.role = role;
      if (code) {
        const wr = await getWorkRequest(code).then((res) => res.json());
        console.log('wr.customLookupCode1', wr.customLookupCode1, lookup1Res.find(
          (x) => x.value === wr.customLookupCode1,
        )?.id);
        setWorkRequest({
          ...wr,
        });

        setNotes(await getNotes(wr?.id));
      } else {
        setWorkRequest({
          createdDate: moment().valueOf(),
          customerCode: detailsResponse.code,
          customerDescription: detailsResponse.description,
          emailAddress: detailsResponse.emailAddress,
          address: detailsResponse.address,
          childWorkType: '',
          contactEmail: detailsResponse.user.email,
          contactName: detailsResponse.user.fullName,
          contactNumber: detailsResponse.user.phoneNumber,
          docType: 'WorkOrderRequest',
          resourceFeedback: '',
        });
      }
      const lookupValues1ResponseTemp = await getCustomLookup1ValuesSsg();
      const lookupValues1Response = lookup1Res.filter((el) => {
        for (let i = 0; i < lookupValues1ResponseTemp.length; i++) {
          if (lookupValues1ResponseTemp[i].value === `${el.id}`) {
            return true;
          }
        }
        return false;
      });
      const workRequestTypesResponse = await byClassAndProp(
        'WorkOrderRequest',
        'workRequestType',
      ).then((res) => res.json());
      setCustomLookup1(lookupValues1Response);
      setTypes(workRequestTypesResponse);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    console.log('WIO', workRequest);
    reset(workRequest);
    setImages(workRequest?.images);
  }, [workRequest]);

  const onSubmit = (data) => {
    console.log(data);

    async function postData() {
      setPosting(true);
      const workRequestObject = {
        ...workRequest,
        ...data,
        customLookupCode1: data.customLookupCode1
          ? data.customLookupCode1
          : customLookup1[0].value,
        typeCode: data.typeCode || types[0]?.value,
      };

      console.log('wr', workRequestObject);

      const response = await postWorkRequest(workRequestObject).then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        const msg = await res.text();
        return {
          result: 'FAIL',
          errorMessage: msg,
          successMessage: null,
          code: '',
          id: 0,
        };
      });

      /**
       * Upload images after a new ticket was successfully logged
       */
      if (!code && response.result === 'SUCCESS' && images?.length > 0) {
        const wr = await getWorkRequest(response.code);
        const wrObj = await wr.json();
        const id = await wrObj.id;

        images?.forEach(async (i) => {
          const formData = new FormData();
          formData.append('workRequestId', id);
          formData.append('file', i.file);
          await addWorkRequestAttachment(formData, 'ssg_fac');
        });
      }

      setSubmitResponse(response);
      setPosting(false);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (code && response.result === 'SUCCESS') {
        history.push('/tickets');
      }
    }
    postData();
  };

  async function addingNoteHandler() {
    if (isAddNote) {
      setIsAddNote(false);
    } else {
      setIsAddNote(true);
    }
  }

  async function submitNoteForm(event) {
    event.preventDefault();
    if (!adding) {
      setAdding(true);
      if (noteText === '') {
        console.log('note: No note text');
      } else {
        const response = await getWorkRequestNoteTypes();
        const workRequestId = workRequest.id;
        const noteTypeId = response[0].value;
        console.log('note form');
        console.log(`work request id: ${workRequestId}`);
        console.log('note type id: ', noteTypeId);
        console.log(`note: ${noteText}`);
        await addNoteToWorkRequest(
          workRequestId,
          noteTypeId,
          noteText,
        );
        setNotes(await getNotes(workRequest.id));
        setNoteText('');
      }
      setAdding(false);
    }
  }

  const closeAddNoteForm = () => {
    setIsAddNote(false);
  };

  const noteInputChangeHandler = (event) => {
    setNoteText(event.target.value);
  };

  console.log('control', control);

  return (
    <>
      {submitResponse !== undefined && submitResponse.result !== 'SUCCESS' && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={submitResponse.result !== 'SUCCESS'}
          onClose={() => setSubmitResponse(undefined)}
        >
          <Alert
            dismissible
            onClose={() => setSubmitResponse(undefined)}
            severity="error"
            elevation={6}
            variant="filled"
          >
            {submitResponse.errorMessage || `Error: Could not ${code ? 'update' : 'log'} ticket.`}
          </Alert>
        </Snackbar>
      )}
      <Form onSubmit={handleSubmit(onSubmit)} css={css`width: 700px`}>
        {!code && submitResponse !== undefined && submitResponse.result === 'SUCCESS' ? (
          <Card css={css`padding: 40px 24px; gap: 40px;`}>
            <div className="new-login-logo-container">
              <img src="ssg_facilities/logo.png" className="new-login-logo-image" alt="SSG Facilities Portal Logo" />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <div
                css={css`
                  color: #262626;
                  font-size: 18px;
                  font-weight: 500;
                `}
              >
                Ticket Submitted Successfully
              </div>
              <div
                css={css`
                  color: #262626;
                  font-size: 14px;
                  font-weight: 400;
                `}
              >
                Your ticket has been submitted! Here&apos;s a ticket number to to use for reference.
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <div
                css={css`
                display: flex;
                flex-direction: row;
                gap: 16px;
                width: 100%;
              `}
              >
                <div
                  css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  border: 1px solid #F0F0F0;
                  padding: 8px 16px;
                  width: 100%;
                `}
                >
                  <div
                    css={css`
                    color: #595959;
                    font-size: 12px;
                    font-weight: 500;
                  `}
                  >
                    Customer Full Name
                  </div>
                  <div
                    css={css`
                    overflow: hidden;
                    color: #262626;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 500;
                  `}
                  >
                    {workRequest !== undefined && workRequest.contactName}
                  </div>
                </div>
                <div
                  css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  border: 1px solid #F0F0F0;
                  padding: 8px 16px;
                  width: 100%;
                `}
                >
                  <div
                    css={css`
                    color: #595959;
                    font-size: 12px;
                    font-weight: 500;
                  `}
                  >
                    Date Issued
                  </div>
                  <div
                    css={css`
                    overflow: hidden;
                    color: #262626;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 500;
                  `}
                  >
                    {dayjs(new Date()).format('DD MMM YYYY')}
                  </div>
                </div>
              </div>
              <div
                css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                border: 1px solid #F0F0F0;
                padding: 8px 16px;
                width: 100%;
              `}
              >
                <div
                  css={css`
                  color: #595959;
                  font-size: 12px;
                  font-weight: 500;
                  text-align: center;
                `}
                >
                  Ticket Number
                </div>
                <div
                  css={css`
                  overflow: hidden;
                  color: #262626;
                  text-align: center;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 25px; 
                `}
                >
                  {submitResponse.code}
                </div>
              </div>
            </div>
            <Button
              type="submit"
              variant="primary"
              onClick={() => { history.push('/tickets'); }}
              css={css`
                height: 45px;
                width: 100%;
                background-color: #E76625 !important;
                border-color: #E76625 !important;
                outline: 0 !important;
                box-shadow: none !important;

                :hover {
                  background-color: #E76625 !important;
                  border-color: #E76625 !important;
                  outline: 0 !important;
                  box-shadow: none !important;
                }

                :focus {
                  background-color: #E76625 !important;
                  border: 1px solid #E76625 !important;
                  outline: 0 !important;
                  box-shadow: none !important;
                }

                :active {
                  background-color: #E76625 !important;
                  border: 1px solid #E76625 !important;
                  outline: 0 !important;
                  box-shadow: none !important;
                }
              `}
            >
              Done
            </Button>
          </Card>
        ) : (
          <Card css={css`padding: 40px 24px;`}>
            <Dimmer inverted active={loading}>
              <Loader>Loading ticket details</Loader>
            </Dimmer>
            <div
              css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          `}
            >
              <div
                role="button"
                tabIndex={0}
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  font-weight: 650;
                  font-size: 12px;
                  line-height: 14px;
                  display: flex;
                  align-items: center;
                  color: #E76625;
                  cursor: pointer;

                  svg {
                    margin-right: 5px;
                  }

                  :hover {
                    color: #F95;
                  }
                `}
                onClick={() => { history.push('/home'); }}
              >
                <ArrowBackIosIcon />
                <div css={css`opacity: 0.5;`}>Back</div>
              </div>
              {code !== undefined && (
              <div
                css={css`
                    color: #E76625;
                    font-weight: bold;
                    font-size: 16px;
                  `}
              >
                {code}
              </div>
              )}
              <div css={css`
              background-image: url("/ssg_facilities/logo_small.png");
              background-blend-mode: overlay;
              background-size: cover;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              width: 73px;
              height: 27px;
            `}
              />
            </div>
            <div
              css={css`
              color: #262626;
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 24px;
            `}
            >
              {code ? 'Edit Ticket' : 'Create Ticket'}
            </div>
            <Card.Body
              className="ml-md-5 mr-md-5"
              css={css`
              padding: 0px;
              margin: 0px !important;

              .row {
                height: fit-content;
                margin: 0px;
                gap: 16px;

                @media (min-width: 768px) {
                  .col-md-6 {
                    flex: 0 0 calc(50% - 8px);
                    max-width: calc(50% - 8px);
                  }
                }

                .col-sm-12 {
                  padding: 0px;

                  .form-group {
                    margin: 0px !important;
                  }
                }
              }

              .imageupload-container {
                margin-bottom: 0px;
              }

              .imageupload-input-text {
                color: #E76625;
                font-weight: 500;
              }
            `}
            >
              <h4
                css={css`
                color: #E76625;
                width: 100%;
                padding-bottom: 8px;
                border-bottom: 1px solid #E76625;
                padding: 12px 0px;
              `}
              >
                Ticket Details
              </h4>
              {code && (
                <Row css={css`margin-bottom: 16px !important;`}>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>
                        {labels['WorkOrderRequest.dateCreated']}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="createdDate"
                        render={({
                          field: {
                            onChange, onBlur, value, ref,
                          },
                        }) => (
                          <Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            value={moment(value).format('DD/MM/YYYY HH:mm')}
                            ref={ref}
                            isInvalid={errors.createdDate}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>
                        {labels['WorkOrderRequest.status']}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="statusDescription"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.statusDescription}
                            readOnly
                            placeholder={labels['WorkOrderRequest.status']}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row css={css`margin-bottom: 16px !important;`}>
                <Col md="12" sm="12">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {labels['WorkOrderRequest.description']}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.description}
                          placeholder="Enter problem description"
                          as="textarea"
                          rows={3}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12" sm="12">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {
                      labels[
                        'WorkOrderRequest.workRequestType.description'
                      ]
                    }
                    </Form.Label>
                    <Controller
                      control={control}
                      name="typeCode"
                      render={({ field }) => (
                        <Form.Control
                          as="select"
                          {...field}
                          isInvalid={errors.typeCode}
                        >
                          {types.map(({ value, label }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </Form.Control>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.typeCode?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <h4
                css={css`
                color: #E76625;
                width: 100%;
                padding-bottom: 8px;
                border-bottom: 1px solid #E76625;
                padding: 12px 0px;
                margin: 16px 0px;
              `}
              >
                Contact Details
              </h4>
              <Row css={css`margin-bottom: 16px !important;`}>
                <Col md="12" sm="12">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {labels['User.fullName']}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="contactName"
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.contactName}
                          placeholder={labels['User.fullName']}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row css={css`margin-bottom: 16px !important;`}>
                <Col md="12" sm="12">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {labels['Customer.emailAddress']}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="contactEmail"
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.contactEmail}
                          placeholder={labels['Customer.emailAddress']}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactEmail?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12" sm="12">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {labels['WorkOrderRequest.contactNumber']}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="contactNumber"
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.contactNumber}
                          placeholder={labels['WorkOrderRequest.contactNumber']}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactNumber?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <h4
                css={css`
                color: #E76625;
                width: 100%;
                padding-bottom: 8px;
                border-bottom: 1px solid #E76625;
                padding: 12px 0px;
                margin: 16px 0px;
              `}
              >
                Location
              </h4>
              <Row>
                <Col md="12" sm="12">
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {labels['WorkOrderRequest.customLookup1']}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="customLookupCode1"
                      render={({ field }) => (
                        <Form.Control
                          as="select"
                          {...field}
                          isInvalid={errors.customLookupCode1}
                        >
                          {customLookup1.map(({ value, label }) => (
                            <option value={value}>
                              {label}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.customLookupCode1?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {code && (
                <div>
                  <div>
                    <h4
                      css={css`
                    color: #E76625;
                    width: 100%;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #E76625;
                    padding: 12px 0px;
                    margin: 16px 0px !important;
                  `}
                    >
                      Notes
                    </h4>
                    {!isAddNote && (
                    <Button
                      css={css`
                    height: 45px;
                    width: 80px;
                    border: 1px solid #E76625;
                    background-color: #E76625;
                    color: #ffffff;

                   :hover {
                      background-color: #F95 !important;
                      border-color: #F95 !important;
                      box-shadow: none !important;
                    }

                    :focus {
                      background-color: #F95 !important;
                      border-color: #F95 !important;
                      box-shadow: none !important;
                    }

                    :active {
                      background-color: #F95 !important;
                      border-color: #F95 !important;
                      box-shadow: none !important;
                    }
                  `}
                      onClick={addingNoteHandler}
                    >
                      Add Note
                    </Button>
                    )}
                  </div>
                  <div
                    css={css`
                  padding-top: 16px;  
                `}
                  >
                    {isAddNote && (
                    <Card css={css`padding: 16px;`}>
                      <h4>Message:</h4>
                      <div css={css`display: flex; flex-direction: column; gap: 16px;`}>
                        <input
                          style={{
                            display: 'block',
                            width: '100%',
                            height: 'calc(1.5em + 0.75rem + 2px)',
                            padding: '0.375rem 0.75rem',
                            fontSize: '1rem',
                            fontWeight: '400',
                            lineHeight: '1.5',
                            color: '#495057',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: '1px solid #ced4da',
                            borderRadius: '0.25rem',
                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                          }}
                          id="noteText"
                          type="text"
                          onChange={noteInputChangeHandler}
                          value={noteText}
                        />
                        <footer css={css`display: flex; flex-direction: row; gap: 16px;`}>
                          <Button
                            css={css`
                          height: 45px;
                          width: 80px;
                          background-color: #E76625 !important;
                          border-color: #E76625 !important;
                          outline: 0 !important;
                          box-shadow: none !important;

                          :hover {
                            background-color: #E76625 !important;
                            border-color: #E76625 !important;
                            outline: 0 !important;
                            box-shadow: none !important;
                          }

                          :focus {
                            background-color: #E76625 !important;
                            border: 1px solid #E76625 !important;
                            outline: 0 !important;
                            box-shadow: none !important;
                          }

                          :active {
                            background-color: #E76625 !important;
                            border: 1px solid #E76625 !important;
                            outline: 0 !important;
                            box-shadow: none !important;
                          }
                        `}
                            onClick={submitNoteForm}
                            disabled={adding}
                          >
                            Add
                          </Button>
                          <Button
                            css={css`
                          height: 45px;
                          width: 80px;
                          border: 1px solid #D9D9D9;
                          background-color: #ffffff;
                          color: #262626;
                        `}
                            onClick={closeAddNoteForm}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                        </footer>
                      </div>
                    </Card>
                    )}
                    {notes.length > 0 && (
                    <div
                      css={css`
                    max-height: 250px;
                    overflow-y: auto;

                    .list-group {
                      .list-group-item {
                        padding: 12px 0px;
                      }
                    }
                  `}
                    >
                      <ListGroup variant="flush">
                        {notes.map((e) => (
                          <ListGroup.Item>
                            <div
                              css={css`
                                display: flex; 
                                flex-direction: row;
                                gap: 16px;
                              `}
                            >
                              <div
                                css={css`
                                  display: flex;
                                  flex-direction: column;
                                `}
                              >
                                {e.noteTypeDescription}
                                <strong>
                                  {moment(e.timestamp).format('DD-MM-yyyy HH:mm')}
                                </strong>
                              </div>
                              <div
                                css={css`
                                  border-right: 2px solid #262626;
                                `}
                              />
                              <div
                                css={css`
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                  `}
                              >
                                {e.noteText}
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                    )}
                    {notes.length === 0 && <p>No notes</p>}
                  </div>
                </div>
              )}
              <h4
                css={css`
                color: #E76625;
                width: 100%;
                padding-bottom: 8px;
                border-bottom: 1px solid #E76625;
                padding: 12px 0px;
                margin: 16px 0px;
              `}
              >
                Photos
              </h4>
              <ImageViewerUploader
                attachmentImages={workRequest?.images}
                images={images}
                setImages={setImages}
                readOnly={!!code}
              />
            </Card.Body>
            <Card.Footer
              css={css`
              margin-top: 24px;
              padding: 24px 0px 0px 0px;
            `}
            >
              <Row
                css={css`
                  display: flex;
                  align-content: center;
                  margin: 0px !important;
                  gap: 16px;
                  width: 100%;
                  height: 45px !important;
                `}
                className="justify-content-around"
              >
                <Link
                  css={css`
                  width: calc(30% - 8px); 
                `}
                  to="/home"
                >
                  <Button
                    css={css`
                    height: 45px;
                    width: 100%;
                    border: 1px solid #D9D9D9;
                    background-color: #ffffff;
                    color: #262626;
                  `}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  css={css`
                  height: 45px;
                  width: calc(70% - 8px);
                  border: 1px solid #E76625 !important;
                  background-color: #E76625 !important;
                  color: #ffffff;

                  :hover {
                    background-color: #F95 !important;
                    border-color: #F95 !important;
                    box-shadow: none !important;
                  }

                  :focus {
                    background-color: #F95 !important;
                    border-color: #F95 !important;
                    box-shadow: none !important;
                  }

                  :active {
                    background-color: #F95 !important;
                    border-color: #F95 !important;
                    box-shadow: none !important;
                  }
                `}
                  disabled={posting}
                  active={posting}
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Row>
            </Card.Footer>
          </Card>
        )}
      </Form>
    </>
  );
};

export default WorkOrderRequestPage;
