import React from 'react';
import {
  Modal,
} from 'react-bootstrap';

const SupportModal = ({ showModal, setShowModal, version }) => (
  <Modal
    centered
    show={showModal}
    onHide={() => setShowModal(false)}
    id="ServestITSupportModal"
    size="md"
  >
    <Modal.Header closeButton>
      <Modal.Title>About Forcelink</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      If you require any assistance please do not hesitate to contact us on:
      <br />
      <br />
      Tel:
      {' '}
      <a href="tel:+27114678528">+27 (0) 11 467 8528</a>
      {' '}
      (8:00 - 17:00)
      <br />
      Tel:
      {' '}
      <a href="tel:+27726712762">+27 (0)72 671 2762</a>
      {' '}
      (17:00 - 8:00)
      <br />
      Email:
      {' '}
      <a href="mailto:support@forcelink.net">support@forcelink.net</a>
      <br />
      <br />
      Forcelink version
      {' '}
      {version}
      <br />
      Acumen Software
      <br />
      <a href="www.forcelink.net">www.forcelink.net</a>
    </Modal.Body>
  </Modal>
);

export default SupportModal;
