import React from 'react';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import { Context } from '../../functions/joburg_water/context';
import '../../styles/joburg_water/logFault.scss';
import { AddressInput } from '../../components/joburg_water/address_input';
import {
  getCallTypes,
  getSubCallTypes,
  logFaultMyAddress,
  logFaultOtherAddress,
} from '../../scripts/joburg_water/logFaultScripts';
import { handleLogging } from '../../functions/forms';
import Ripple from '../../components/joburg_water/ripple';
import SlideIn from '../../components/joburg_water/slidein';
import {
  getProfile,
} from '../../scripts/joburg_water/profileScripts';
import ImageDrop from './ImageDrop';
import { addWorkRequestAttachment } from '../../functions/apiCalls';

class LogFault extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const { otherAddress } = this.props;
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: otherAddress ? this.initEntriesWithMap() : this.initEntries(),
      requiredMissing: otherAddress
        ? this.initRequiredMissingWithMap()
        : this.initRequiredMissing(),
      requireNumber: false,
      callTypesList: [],
      subCallTypesList: [],
      subSubCallTypesList: [],
      submitting: false,
      images: [],
    };
  }

  initEntries = () => ({
    callType: '',
    subCallType: '',
    subSubCallType: '',
    callNotes: '',
    meterNumber: '',
    accountNumber: '',
  });

  initEntriesWithMap = () => ({
    ...this.initEntries(),
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    description: '',
  });

  initRequiredMissing = () => ({
    callType: false,
    callNotes: false,
  });

  initRequiredMissingWithMap = () => ({
    ...this.initRequiredMissing(),
    contactPerson: false,
    mobileNumber: false,
    streetNumber: false,
    streetName: false,
    suburb: false,
  });

  componentDidMount = async () => {
    try {
      const profileData = await getProfile();
      const {
        mobileNumber, meterNumber, emailAddress, contactPerson, accountNumber,
      } = profileData;

      // Auto populate these fields with profile data
      this.setState((prevState) => ({
        entries: {
          ...prevState.entries,
          mobileNumber,
          meterNumber,
          emailAddress,
          contactPerson,
          accountNumber,
        },
      }));
    } catch (error) {
      // Handle the error here, e.g., show an error message to the user or log the error.
      console.error('Error fetching profile data:', error);
    }

    const callTypesList = await this.generateCallTypes();
    this.setState({ callTypesList });
  };

  generateCallTypes = async () => {
    const { callTypes, setCallTypes } = this.context;
    console.log('callTypes:', callTypes);
    if (callTypes == null || callTypes.length === 0) {
      console.log('getting call types from API');
      const cT = (await getCallTypes()) || [];
      setCallTypes(cT);
      return cT;
    }
    console.log('getting call types from React context');
    return callTypes;
  };

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (name, value) => this.setEntries(name, value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (name, value) => {
    const newVal = this.updateEntries(name, value);
    if (newVal !== '') {
      this.initRequiredState(name);
    }
  };
  // << UPDATE VALUES

  onResultFound = (result) => {
    console.log('result passed to parent:', result);
    const {
      streetNumber, streetName, city, postalCode, suburb,
    } = result;
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || entries.streetNumber || '';
    toUpdate.streetName = streetName || entries.streetName || '';
    toUpdate.city = city || entries.city || '';
    toUpdate.postalCode = postalCode || entries.postalCode || '';
    toUpdate.suburb = suburb || entries.suburb || '';
    this.setState({ entries: toUpdate });
  };

  validStyle = (name) => {
    const {
      requiredMissing,
    } = this.state;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  render() {
    const { title, otherAddress } = this.props;
    const {
      entries,
      images,
      entries: {
        callType,
        subCallType,
        subSubCallType,
        callNotes,
        meterNumber,
        accountNumber,
        contactPerson,
        mobileNumber,
        emailAddress,
      },
      requiredMissing,
      callTypesList,
      subCallTypesList,
      subSubCallTypesList,
      submitting,
      requiredNumber,
    } = this.state;
    console.log('state:', this.state);

    if (subCallTypesList && subCallTypesList.length !== 0) {
      // Initialise states for sub call input when there is a sub call necessary
      requiredMissing.subCallType == null
        && this.initRequiredState('subCallType');
    } else {
      // When sub call not necessary, reset states for sub call
      if (subCallType !== '') {
        this.setEntries('subCallType', '');
      }
      if (requiredMissing.subCallType != null) {
        this.updateRequiredState('subCallType', null);
      }
    }

    if (subSubCallTypesList && subSubCallTypesList.length !== 0) {
      // Initialise states for sub call input when there is a sub call necessary
      requiredMissing.subSubCallType == null
        && this.initRequiredState('subSubCallType');
    } else {
      // When sub call not necessary, reset states for sub call
      if (subSubCallType !== '') {
        this.setEntries('subSubCallType', '');
      }
      if (requiredMissing.subSubCallType != null) {
        this.updateRequiredState('subSubCallType', null);
      }
    }

    return (
      <Container fluid id="formContainer">
        <div className="slide-container">
          <SlideIn settings={{
            marginRight: 0,
            width: '100%',
            from: { marginRight: -700 },
            config: { mass: 1, tension: 300, friction: 30 },
          }}
          >
            <Card id="formCard" className="text-center">
              <Card.Header>
                <img src="joburg_water/logo.jpg" className="login-logo" alt="Joburg Water Logo" />
                <div className="login-title">
                  {title}
                </div>
              </Card.Header>
              <Card.Body id="formBody">
                <Form>
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Fault Information
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* CALL TYPE */}
                      <Col md={6}>
                        <Material.TextField
                          name="callType"
                          select
                          fullWidth
                          margin="normal"
                          label="Level One Call Type"
                          variant="outlined"
                          required
                          error={requiredMissing.callType === true}
                          value={callType}
                          onChange={async (e) => {
                            this.updateReqEntries(e.target.name, e.target.value);
                            const callTypeCode = e.target.value;
                            const res = await getSubCallTypes(callTypeCode);
                            this.setState({
                              subCallTypesList: res.filter(
                                (item) => item.value !== callTypeCode,
                              ),
                            });
                          }}
                        >
                          <Material.MenuItem
                            key="blank"
                            value=""
                          />
                          {callTypesList.map((type) => (
                            <Material.MenuItem
                              key={type.value}
                              value={type.value}
                            >
                              {type.label}
                            </Material.MenuItem>
                          ))}
                        </Material.TextField>
                      </Col>
                      {/* SUB CALL TYPE */}
                      <Col md={6}>
                        {subCallTypesList && subCallTypesList.length !== 0 && (
                          <Material.TextField
                            name="subCallType"
                            select
                            fullWidth
                            margin="normal"
                            label="Level Two Call Type"
                            variant="outlined"
                            required
                            error={requiredMissing.subCallType === true}
                            value={subCallType}
                            onChange={async (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                              const callTypeCode = e.target.value;
                              const res = await getSubCallTypes(callTypeCode);
                              this.setState({
                                subSubCallTypesList: res.filter(
                                  (item) => item.value !== callTypeCode && item.value !== callType,
                                ),
                              });
                            }}
                          >
                            {subCallTypesList.map((type) => (
                              <Material.MenuItem
                                key={type.value}
                                value={type.value}
                              >
                                {type.label.replaceAll('&nbsp;', '')}
                              </Material.MenuItem>
                            ))}
                          </Material.TextField>
                        )}
                      </Col>
                    </Row>
                    {/* SUB SUB CALL TYPE */}
                    {subSubCallTypesList && subSubCallTypesList.length !== 0 && (
                      <Row className="formInputs">
                        <Col md={6}>
                          <Material.TextField
                            name="subSubCallType"
                            select
                            fullWidth
                            margin="normal"
                            label="Level Three Call Type"
                            variant="outlined"
                            required
                            error={requiredMissing.subSubCallType === true}
                            value={subSubCallType}
                            onChange={
                              (e) => {
                                this.updateReqEntries(e.target.name, e.target.value);
                              }
                            }
                          >
                            {subSubCallTypesList.map((type) => (
                              <Material.MenuItem
                                key={type.value}
                                value={type.value}
                              >
                                {type.label.replaceAll('&nbsp;', '')}
                              </Material.MenuItem>
                            ))}
                          </Material.TextField>
                        </Col>
                        <Col md={6} />
                      </Row>
                    )}
                  </Form.Group>
                  {/* CALL NOTES */}
                  <Form.Group className="formLabels">
                    <Form.Label
                      className="smallLabel"
                      style={this.validStyle('callNotes')}
                    >
                      Call Notes *
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="formInputs">
                    <Form.Control
                      name="callNotes"
                      as="textarea"
                      rows={3}
                      required
                      isInvalid={requiredMissing.callNotes === true}
                      value={callNotes}
                      onChange={
                        (e) => {
                          this.updateReqEntries(e.target.name, e.target.value);
                        }
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* ACCOUNT NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel" style={this.validStyle('accountNumber')}>
                            Account Number *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="accountNumber"
                          type="text"
                          isInvalid={requiredNumber === true}
                          value={accountNumber}
                          onChange={(e) => {
                            this.updateEntries(e.target.name, e.target.value);
                            if (accountNumber === '') {
                              this.setState({ requiredNumber: true });
                            } else {
                              this.setState({ requiredNumber: false });
                            }
                          }}
                        />
                      </Col>
                      {/* METER NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel" style={this.validStyle('meterNumber')}>
                            Meter Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="meterNumber"
                          type="text"
                          value={meterNumber}
                          onChange={(e) => {
                            this.updateEntries(e.target.name, e.target.value);
                            if (accountNumber === '') {
                              this.setState({ requiredNumber: true });
                            } else {
                              this.setState({ requiredNumber: false });
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {otherAddress && (
                    <>
                      <Form.Group className="formLabels">
                        <Form.Label className="largeLabel">
                          Contact Details
                        </Form.Label>
                      </Form.Group>
                      <Form.Group>
                        <Row className="formInputs">
                          {/* CONTACT PERSON */}
                          <Col md={6}>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={this.validStyle('contactPerson')}
                              >
                                Contact Person *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="contactPerson"
                              type="text"
                              required
                              isInvalid={requiredMissing.contactPerson === true}
                              value={contactPerson}
                              onChange={
                                (e) => {
                                  this.updateReqEntries(e.target.name, e.target.value);
                                }
                              }
                            />
                          </Col>
                          {/* CONTACT NUMBER */}
                          <Col md={6}>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={this.validStyle('mobileNumber')}
                              >
                                Contact Number *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="mobileNumber"
                              type="number"
                              required
                              isInvalid={requiredMissing.mobileNumber === true}
                              value={mobileNumber}
                              onChange={
                                (e) => {
                                  this.updateReqEntries(e.target.name, e.target.value);
                                }
                              }
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row className="formInputs">
                          {/* CONTACT EMAIL */}
                          <Col xs={12} md={6}>
                            <Form.Group className="formLabels">
                              <Form.Label className="smallLabel">
                                Contact Email
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="emailAddress"
                              type="email"
                              value={emailAddress}
                              onChange={(e) => {
                                this.updateEntries(e.target.name, e.target.value);
                              }}
                            />
                          </Col>
                          <Col xs={0} md={6} />
                        </Row>
                      </Form.Group>

                      {/* to implement component,
                      initalise parent state 'entries' with the initEntriesWithMap function,
                      and similarly with 'requiredMissing' */}
                      <AddressInput
                        entries={entries}
                        onChange={(name, value) => {
                          this.updateEntries(name, value);
                        }}
                        requiredMissing={requiredMissing}
                        updateReqEntries={(name, value) => {
                          this.updateReqEntries(name, value);
                        }}
                        onResultFound={this.onResultFound}
                        page="logFault"
                      />
                    </>
                  )}
                  <ImageDrop
                    workImages={undefined}
                    images={images}
                    setImages={(value) => { this.setState({ images: value }); }}
                    workRequestId={undefined}
                    refresh={undefined}
                  />
                </Form>
              </Card.Body>
              <Card.Footer>
                <div className="button-group">
                  <Form.Group id="finalButtons">
                    <Link to="/home">
                      <Button className="secondary" type="submit">
                        Cancel
                        <Ripple />
                      </Button>
                    </Link>
                    <div id="submit-spinner">
                      {!submitting && (
                        <Button
                          type="submit"
                          className="primary"
                          onClick={async () => {
                            this.setState({
                              submitting: true,
                            });
                            let isValidNumber = true;
                            let isValidEmail = true;
                            if (otherAddress) {
                              const regexPhone = /^0\d{9}$/;
                              isValidNumber = regexPhone.test(mobileNumber);
                              const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              isValidEmail = regexEmail.test(entries.emailAddress);
                            }
                            if (!isValidNumber) {
                              alert('Invalid mobile number, please start with a 0 and provide a ten digit number.');
                            } else if (!isValidEmail) {
                              alert('Invalid email address.');
                            } else {
                              let allowsub = false;
                              if (accountNumber === '') {
                                allowsub = false;
                                this.setState({ requiredNumber: true });
                              } else {
                                this.setState({ requiredNumber: false });
                                allowsub = true;
                              }
                              if (allowsub) {
                                if (
                                  handleLogging(this.state, (s) => this.setState(s)) === true
                                ) {
                                  let response = '';
                                  if (!otherAddress) {
                                    response = await logFaultMyAddress(entries,
                                      (s) => this.setState(s));
                                  }
                                  if (otherAddress) {
                                    response = await logFaultOtherAddress(entries,
                                      (s) => this.setState(s));
                                  }
                                  if (response !== undefined && response !== '' && response.result !== 'FAILURE') {
                                    const uploadImages = async () => {
                                      const uploadPromises = images.map((image) => {
                                        const formData = new FormData();
                                        formData.append('workRequestId', response.id);
                                        formData.append('file', image);
                                        return addWorkRequestAttachment(formData, 'joburg_water');
                                      });
                                      await Promise.all(uploadPromises);
                                    };
                                    await uploadImages();
                                    window.location.pathname = 'joburg_water/home';
                                  }
                                }
                              } else {
                                if (handleLogging(this.state, (s) => this.setState(s)) === true) {
                                  alert('You are missing some required fields');
                                }
                                this.setState({ requiredNumber: true, submitting: false });
                              }
                            }
                            this.setState({
                              submitting: false,
                            });
                          }}
                        >
                          Save and continue
                          <Ripple />
                        </Button>
                      )}
                      {submitting && <Spinner />}
                    </div>
                  </Form.Group>
                </div>
              </Card.Footer>
            </Card>
          </SlideIn>
        </div>
      </Container>
    );
  }
}

export const LogFaultMyAddress = () => <LogFault title="Log a fault at My Address" />;

export const LogFaultOtherAddress = () => <LogFault title="Log a fault at Another Address" otherAddress />;
